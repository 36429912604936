import {
  ApplyProductMutation,
  ApplyProductMutationVariables,
  ProductPricingQuery,
  ProductPricingQueryVariables,
  ValidateActivationCodeQuery,
  ValidateActivationCodeQueryVariables,
} from "../../gql";
import {
  BREAKPOINT_EXTRA_SMALL,
  BREAKPOINT_SMALL,
  BREAKPOINT_SMALL_MIN,
} from "../../config";
import { CircularProgress, TextField } from "@mui/material";
import { EPaywallMode, usePaywall } from "../../providers/PaywallProvider";
import { useMutation, useQuery } from "@apollo/client";

import { Button } from "@vestaboard/installables";
import { FlatButton } from "../FlatButton";
import { LightBoardDropdown } from "../BoardSelector";
import { OutlinedButton } from "../OutlinedButton";
import { OutlinedNumberStepper } from "../OutlinedNumberStepper";
import React from "react";
import gql from "graphql-tag";
import { makeStyles } from "@mui/styles";
import { useDebounce } from "use-debounce";
import { useFlags } from "launchdarkly-react-client-sdk";

export interface InstallablesPlusUpgradeModalProps {
  shown: boolean;
  onClose(): void;
  className?: string;
  contextCopy?: string | null;
  hideActivationCodeButton?: boolean;
  openLinksInNewWindow?: boolean;
}

const useStyles = makeStyles({
  modalContent: {
    display: "flex",
    flexDirection: "column",
    color: "#1e2022",
    alignItems: "center",
    "&::before": {
      content: "''",
      backgroundImage: "url('/paywall-left.png')",
      position: "absolute",
      left: 0,
      top: 0,
      width: 400,
      display: "block",
      height: "100%",
      backgroundRepeat: "no-repeat",
      zIndex: -1,
      backgroundSize: "70%",
      borderTopLeftRadius: "8px",
      [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
        display: "none",
        paddingBottom: 36,
      },
      [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
        width: 300,
      },
    },
    "&::after": {
      content: "''",
      backgroundImage: "url('/paywall-right.png')",
      position: "absolute",
      right: 0,
      top: 0,
      width: 400,
      display: "block",
      height: "100%",
      backgroundRepeat: "no-repeat",
      zIndex: -1,
      backgroundSize: "70%",
      backgroundPosition: "top right",
      borderTopRightRadius: "8px",
      [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
        width: 300,
      },
      [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
        width: 250,
      },
    },
  },
  logo: {
    marginBottom: 16,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      marginTop: 32,
      marginBottom: 32,
      alignSelf: "start",
    },
  },
  modalHeading: {
    textAlign: "center",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      textAlign: "left",
    },
  },
  tagline: {
    fontFamily: "HelveticaNeue",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "0.15px",
    color: "#1e2022",
    marginBottom: 8,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      fontSize: 18,
      maxWidth: "90%",
      alignSelf: "start",
    },
  },
  description: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.63,
    letterSpacing: "0.15px",
    color: "#1e2022",
    marginBottom: 34,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      width: "80%",
      margin: "0 auto 34px",
    },
    // @ts-ignore
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      lineHeight: 1.25,
      width: "100%",
    },
  },
  card: {
    borderRadius: 8,
    boxShadow: "0 36px 39px 0 rgba(126, 135, 142, 0.15)",
    backgroundColor: "#FFF",
    position: "relative",
  },
  valueTag: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    boxShadow: "0 39px 39px 0px rgba(126,135,142,0.15)",
    borderRadius: "12px",
    position: "absolute",
    top: "-5%",
    padding: "4px 38px",
  },
  numBoardsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 24px",
    maxWidth: 250,
    marginBottom: 39,
    [`@media(min-width: ${BREAKPOINT_SMALL_MIN})`]: {
      alignSelf: "center",
    },
  },
  numBoardsLabel: {
    fontFamily: "HelveticaNeue",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#1e2022",
    marginRight: 10,
    marginBottom: 0,
  },
  numBoardsInput: {
    marginTop: 0,
    marginBottom: 0,
  },
  pricingOptions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 36,
    position: "relative",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      display: "block",
      marginBottom: 24,
    },
  },
  pricingOption: {
    width: 212,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 16px",
    padding: "40px 35px 25px 35px",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      margin: "0 0 32px 0",
    },
  },
  pricingOptionTag: {},
  pricingOptionLength: {
    fontFamily: "HelveticaNeue",
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#1e2022",
    marginBottom: 38,
  },
  pricingOptionTagline: {
    fontFamily: "HelveticaNeue",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: 1.57,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#5c5f64",
    marginBottom: 32,
  },
  pricingOptionMonthlyValue: {
    fontFamily: "HelveticaNeue",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#1e2022",
    marginBottom: 30,
  },
  pricingOptionGap: {
    flexGrow: 1,
  },
  pricingOptionBtn: {
    width: "100%",
    color: "white !important",
    "& p": {
      opacity: "1 !important",
      fontSize: 16,
      lineHeight: 1.63,
    },
    marginBottom: 14,
    marginTop: 16,
    borderRadius: 5,
    backgroundColor: "#1e2022",
  },
  pricingOptionBilledAmt: {
    fontFamily: "HelveticaNeue",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#1e2022",
    marginBottom: 0,
  },
  outlinedButton: {
    fontFamily: "HelveticaNeue",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: 2.17,
    letterSpacing: "0.15px",
    textAlign: "center",
    padding: "4px 24px",
  },
  bigOutlined: {
    fontSize: 16,
  },
  loadingContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.02)",
    backdropFilter: "blur(3px)",
    zIndex: 10,
    borderRadius: 10,
    margin: "-8px 8px",
  },
  inputInstructions: {
    color: "#1e2022",
    marginBottom: 8,
    marginTop: 32,
    [`@media(min-width: ${BREAKPOINT_SMALL_MIN})`]: {
      marginTop: 100,
    },
  },
  activationCodeContainer: {
    width: "100%",
    maxWidth: 375,
    textAlign: "center",
  },
  boardSelectorContainer: {
    width: "100%",
    maxWidth: 375,
    textAlign: "center",
  },
  fullWidthInput: {
    width: "100%",
    "& .MuiInputBase-input": {
      color: "#1e2022",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1e2022",
      borderWidth: 1,
    },
  },
  validationPass: {
    "&::before": {
      color: "#ffffff",
      position: "absolute",
      padding: "2px 20px",
      minWidth: 134,
      content: "'Valid code'",
      backgroundColor: "#2e9a42",
      bottom: "-15%",
      fontSize: 10,
      borderRadius: 6,
      zIndex: 1,
      right: 14,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#2e9a42",
      },
  },
  validationFailInvalid: {
    "&::before": {
      color: "#ffffff",
      position: "absolute",
      padding: "2px 20px",
      minWidth: 134,
      content: "'Not a valid code'",
      backgroundColor: "#d52819",
      bottom: "-15%",
      fontSize: 10,
      borderRadius: 6,
      zIndex: 1,
      right: 14,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#d52819",
      },
  },
  validationFailUsed: {
    "&::before": {
      color: "#ffffff",
      position: "absolute",
      padding: "2px 20px",
      minWidth: 134,
      content: "'Code in use'",
      backgroundColor: "#d52819",
      bottom: "-15%",
      fontSize: 10,
      borderRadius: 6,
      zIndex: 1,
      right: 14,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#d52819",
      },
  },
  dropdownLight: {
    textAlign: "left",
    marginTop: 24,
    position: "relative",
    "& .MuiInputBase-root": {
      color: "#1e2022",
      border: "1px solid #171818",
    },
    "& .MuiInputLabel-root": {
      color: "#1e2022",
    },
    "& .MuiInputLabel-formControl:not(.MuiInputLabel-shrink)": {
      transform: "translate(12px, 30px) scale(1)",
    },
    "& .MuiInput-underline": {
      "&::before": {
        content: "unset",
      },
    },
    "& .MuiSelect-icon": {
      right: 7,
    },
    "&::before": {
      content: "url(/icons/icon-keyboard-dark.svg)",
      position: "absolute",
      right: 40,
      padding: 14,
      top: 16,
      bottom: 0,
      borderRight: "1px solid #171818",
    },
  },
  virtualBoardPairContainer: {
    width: "100%",
    maxWidth: 470,
    [`@media(min-width: ${BREAKPOINT_SMALL_MIN})`]: {
      marginTop: 42,
      textAlign: "center",
    },
  },
});

const VESTABOARD_PLUS_PRODUCT_ID = "ba25bb32-7a28-4a69-a34b-87a3fdeaadd6";

const APPLY_PRODUCT_MUTATION = gql`
  mutation ApplyProductMutation($board: String!, $code: String!) {
    applyRedemptionCode(input: { board: $board, code: $code }) {
      productApplication {
        id
        board {
          id
        }
      }
    }
  }
`;

const useApplyProductMutation = () =>
  useMutation<ApplyProductMutation, ApplyProductMutationVariables>(
    APPLY_PRODUCT_MUTATION
  );

const PRODUCT_PRICING_QUERY = gql`
  query ProductPricingQuery($quantity: Int!) {
    product(id:"${VESTABOARD_PLUS_PRODUCT_ID}"){
      id
      productPricings(quantity: $quantity) {
        id
        chargeFrequency
        title
        priceInCents
        pricePerMonthInCents
        estimatedPriceInCents {
          totalPrice
          cartLink
        }
      }
    }
  }
`;

export const useProductPricingQuery = (quantity: number) =>
  useQuery<ProductPricingQuery, ProductPricingQueryVariables>(
    PRODUCT_PRICING_QUERY,
    {
      variables: {
        quantity,
      },
      fetchPolicy: "no-cache",
    }
  );

interface IProductPricingOption {
  id: string;
  chargeFrequency: string;
  quantity: number;
  title: string;
  priceInCents: number;
  pricePerMonthInCents: number;
  estimatedPriceInCents: {
    totalPrice: number;
    cartLink: {
      url: string;
    };
  };
}

const useProductPricingOptions = () => {
  const [data, setData] = React.useState<IProductPricingOption[]>([]);
  const [loading, setLoading] = React.useState(true);

  const load = async () => {
    setLoading(true);
    const response = await window.fetch(
      "https://platform.vestaboard.com/v3.0/shopify/product-pricings"
    );
    const data = await response.json();
    setData(data);
    setLoading(false);
  };

  React.useEffect(() => {
    load();
  }, []);

  return { data, loading };
};

export const InstallablesPlusUpgradeModalContents: React.FC<
  InstallablesPlusUpgradeModalProps
> = (props) => {
  const classes = useStyles();
  const [numBoards, _setNumBoards] = React.useState(1);
  const { data, loading } = useProductPricingOptions();
  const { setMode } = usePaywall();

  const setNumBoards = (num: number) => {
    if (num > 0 && num <= 10) {
      _setNumBoards(num);
    }
  };

  const [initialLoading, setInitialLoading] = React.useState(false);

  React.useEffect(() => {
    if (loading && !initialLoading) {
      setInitialLoading(true);
    }

    if (loading && initialLoading) {
      setInitialLoading(false);
    }

    if (!loading && initialLoading) {
      setInitialLoading(false);
    }
  }, [loading]);

  return (
    <div
      className={classes.modalContent}
      style={{
        minHeight: "100vh",
      }}
    >
      <img
        src={"/vestaboard-plus-logo-black.svg"}
        height={30}
        className={classes.logo}
        alt="Vestaboard logo"
        style={{ marginBottom: 40 }}
      />
      <div className={classes.modalHeading}>
        <h1 className={classes.tagline}>
          {props.contextCopy
            ? `${props.contextCopy} and everything else`
            : `Get Vestaboard Plus`}
        </h1>
        <h2 className={classes.description}>
          Automate messages with an ever growing selection of content and
          integrations.
        </h2>
      </div>
      <div className={[classes.card, classes.numBoardsContainer].join(" ")}>
        <label className={classes.numBoardsLabel}>Number of Vestaboards:</label>
        <OutlinedNumberStepper
          value={numBoards}
          onChange={setNumBoards}
          maxInputWidth={80}
          className={classes.numBoardsInput}
        />
      </div>
      <div className={classes.pricingOptions}>
        {loading && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
        {(
          data
            .filter((pp) => pp.quantity === numBoards)
            .sort((pp1, pp2) => pp1.priceInCents - pp2.priceInCents)
            .map((pp) => ({
              id: pp.id,
              length: pp.chargeFrequency,
              title: pp.title,
              monthlyValue: pp.pricePerMonthInCents
                ? `Less than ${formatDollars(pp.pricePerMonthInCents)}/month`
                : null,
              billedAmount: pp.estimatedPriceInCents.totalPrice,
              link: pp.estimatedPriceInCents.cartLink,
              chargeFrequency: pp.chargeFrequency,
            })) ?? []
        ).map((pricingOption) => (
          <div
            key={pricingOption.id}
            className={[classes.card, classes.pricingOption].join(" ")}
          >
            {pricingOption.chargeFrequency === "EveryThreeYears" && (
              <span className={classes.valueTag}>Best Value</span>
            )}
            <h4 className={classes.pricingOptionLength}>
              {pricingOption.title}
            </h4>
            <div className={classes.pricingOptionGap} />
            <p className={classes.pricingOptionMonthlyValue}>
              {pricingOption.monthlyValue}
            </p>
            <Button
              buttonType="black"
              onClick={() => {
                if (pricingOption.link) {
                  const shopifyLink = `${pricingOption.link.url}`;

                  if (props.openLinksInNewWindow) {
                    window.open(shopifyLink, "_parent");
                  } else {
                    window.location.href = shopifyLink;
                  }
                }
              }}
            >
              Get {pricingOption.title}
            </Button>
            <p className={classes.pricingOptionBilledAmt}>
              Billed {formatDollars(pricingOption.billedAmount)}
            </p>
          </div>
        ))}
      </div>
      {!props.hideActivationCodeButton && (
        <OutlinedButton
          onClick={() => setMode(EPaywallMode.Activating)}
          color={"black"}
          className={classes.outlinedButton}
        >
          I have an activation code
        </OutlinedButton>
      )}
    </div>
  );
};

interface IInstallablesPlusActivateModal {
  shown: boolean;
  code?: string | null;
  onClose: () => void;
  openPostActivateModal: () => void;
}

const ACTIVATION_CODE_VALIDATION_QUERY = gql`
  query ValidateActivationCode($code: String!) {
    activationCodeQuery(code: $code) {
      isValid
      message
    }
  }
`;

const useActivationCodeValidationQuery = (code: string) =>
  useQuery<ValidateActivationCodeQuery, ValidateActivationCodeQueryVariables>(
    ACTIVATION_CODE_VALIDATION_QUERY,
    {
      variables: {
        code,
      },
      skip: !(code.length > 6),
      fetchPolicy: "network-only",
    }
  );

export const InstallablesPlusActivateModalContents: React.FC<
  IInstallablesPlusActivateModal
> = (props) => {
  const classes = useStyles();
  const [activationCode, _setActivationCode] = React.useState("");
  const { setMode, initialCode } = usePaywall();
  const [selectedBoard, setSelectedBoard] = React.useState("");
  const [applyMutation] = useApplyProductMutation();
  const [error, setError] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [showBoardDropdown, setShowBoardDropdown] = React.useState(false);
  const flags = useFlags();
  const [debouncedActivationCode] = useDebounce(activationCode, 100);
  const { data: validateData } = useActivationCodeValidationQuery(
    debouncedActivationCode
  );
  const codeIsValid = validateData?.activationCodeQuery?.isValid;
  const invalidCodeMessage = validateData?.activationCodeQuery?.message;

  React.useEffect(() => {
    setActivationCode(initialCode);
  }, [initialCode]);

  const setActivationCode = (newCode: string) => {
    _setActivationCode(newCode);
  };

  const activate = async () => {
    try {
      setSaving(true);
      if (activationCode && !!selectedBoard) {
        await applyMutation({
          variables: {
            board: selectedBoard,
            code: activationCode,
          },
        });
        setSaving(false);
        setError(false);
        // The end of post activate flow will reload the app and all queries.
        if (flags.blendedV1) {
          props.openPostActivateModal();
          props.onClose();
        } else {
          window.location.reload();
        }
      }
    } catch (e) {
      console.error(e);
      setSaving(false);
      setError(true);
    }
  };

  return (
    <div className={classes.modalContent}>
      <img
        src={"/vestaboard-plus-logo-black.svg"}
        height={30}
        className={classes.logo}
        alt="Vestaboard logo"
      />
      <h1 className={classes.tagline}>Activate your plan</h1>

      {!showBoardDropdown ? (
        <div className={classes.activationCodeContainer}>
          <p className={classes.inputInstructions}>
            Enter your one-time code below to get started
          </p>
          <TextField
            value={activationCode}
            onChange={(e) => setActivationCode(e.target.value)}
            placeholder="Activation code"
            className={`${classes.fullWidthInput} ${
              debouncedActivationCode && codeIsValid
                ? classes.validationPass
                : ""
            } ${
              debouncedActivationCode && !codeIsValid
                ? invalidCodeMessage === "Activation code already used"
                  ? classes.validationFailUsed
                  : classes.validationFailInvalid
                : ""
            }`}
            variant="outlined"
          />
          <FlatButton
            onClick={() => setShowBoardDropdown(true)}
            className={classes.pricingOptionBtn}
            style={{ marginBottom: 132 }}
            disabled={!codeIsValid}
          >
            Next
          </FlatButton>
          <OutlinedButton
            onClick={() => setMode(EPaywallMode.Buying)}
            color={"black"}
            className={`${classes.outlinedButton} ${classes.bigOutlined}`}
          >
            Purchase an activation code
          </OutlinedButton>
        </div>
      ) : (
        <div className={classes.boardSelectorContainer}>
          <p className={classes.inputInstructions}>
            Select the Vestaboard you would like to apply the one-time
            activation code toward
          </p>
          <LightBoardDropdown
            value={selectedBoard}
            onChange={(v) => setSelectedBoard(v)}
            hidePlusActivatedBoards
            hideVirtualBoards
          />
          <FlatButton
            disabled={!codeIsValid}
            onClick={async () => await activate()}
            className={classes.pricingOptionBtn}
            style={{ marginBottom: 132 }}
          >
            {saving ? <CircularProgress /> : "Activate Now"}
          </FlatButton>
        </div>
      )}
    </div>
  );
};

interface IInstallablesPlusVirtualBoardModalContents {
  shown: boolean;
  onClose: () => void;
}

export const InstallablesPlusVirtualBoardModalContents: React.FC<
  IInstallablesPlusVirtualBoardModalContents
> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.modalContent}>
      <img
        src={"/vestaboard-plus-logo-black.svg"}
        height={30}
        className={classes.logo}
        alt="Vestaboard logo"
      />
      <div className={classes.virtualBoardPairContainer}>
        <h1 className={classes.tagline}>
          This Virtual Vestaboard is not paired to a device
        </h1>
        <p>
          Vestaboard+ can only be activated on a Vestaboard device that has been
          paired and connected to the internet.
        </p>
        <FlatButton
          onClick={() => (window.location.href = "http://vestaboard.com/app")}
          className={classes.pricingOptionBtn}
        >
          Pair Now with Mobile App
        </FlatButton>
      </div>
    </div>
  );
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}).format;

const formatDollars = (cents: number) => formatter(cents / 100);
